<div class="page-container" [@simpleFadeAnimation]="'in'">
  <div class="panel">
    <div class="inner-panel">
      <h1 class="lofi-header">{{'RELEASES.HEADERS.LABEL' | translate}}</h1>
      <article class="notice">
        {{'RELEASES.CONTENT.LABEL' | translate}}

        <br />

        <ul>
          <div class="cover cover-2" (click)="toggleSound2()">
            <img class="toggle-sound" src="../../../assets/images/icons/play.png" alt="play sample button" />
            <span class="sample">{{'RELEASES.CONTENT.SAMPLE' | translate}}</span>
          </div>
          <li class="bg">
            <h1>{{'RELEASES.HEADERS.ALBUM-2' | translate}}</h1>
          </li>
          <a class="platform-link" href="https://open.spotify.com/album/7GButMFLBz24hBHB1pKhNP">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-spotify.svg" alt="spotify logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://www.deezer.com/us/album/425921607">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-deezer.svg" alt="deezer logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://music.amazon.com/albums/B0C1H9BF7X">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-amazonmusic.svg" alt="amazon music logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://www.amazon.com/dp/B0C1H9BF7X">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-amazon.svg" alt="amazon logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.BUY' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://music.apple.com/us/album/dusk-horizon/1680664224">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-applemusic.svg" alt="apple music logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://play.anghami.com/album/1037937327?refer=found">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-anghami.svg" alt="anghami logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://lnk.dmsmusic.co/variousartists_duskhorizon/ms-pandora-6">
            <li class="platform last-platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-pandora.svg" alt="pandora logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
        </ul>

        <br />

        <ul>
          <div class="cover cover-1" (click)="toggleSound1()">
            <img class="toggle-sound" src="../../../assets/images/icons/play.png" alt="play sample button" />
            <span class="sample">{{'RELEASES.CONTENT.SAMPLE' | translate}}</span>
          </div>
          <li class="bg">
            <h1>{{'RELEASES.HEADERS.ALBUM-1' | translate}}</h1>
          </li>
          <a class="platform-link" href="https://open.spotify.com/album/1BJuBDisqlZzkPYVYAJzxl">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-spotify.svg" alt="spotify logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://www.deezer.com/en/album/305146947">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-deezer.svg" alt="deezer logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://music.amazon.com/albums/B09W8LV98Q?tag=fndcmpgns-20">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-amazonmusic.svg" alt="amazon music logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://www.amazon.com/dp/B09W8LV98Q?tag=fndcmpgns-20">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-amazon.svg" alt="amazon logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.BUY' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://music.apple.com/gr/album/aphrodites-memories/1615636083">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-applemusic.svg" alt="apple music logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://play.anghami.com/album/1027009819?refer=found">
            <li class="platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-anghami.svg" alt="anghami logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
          <a class="platform-link" href="https://lnk.dmsmusic.co/variousartists_aphroditesmemories/ms-pandora-5">
            <li class="platform last-platform">
              <img class="platform-logo" src="../../../assets/images/platforms/logo-pandora.svg" alt="pandora logo" />
              <div class="platform-action">{{'RELEASES.BUTTONS.LISTEN' | translate}}</div>
            </li>
          </a>
        </ul>

      </article>
    </div>
  </div>

  <div class="panel yellow">
    <div class="inner-panel">
      <h1 class="lofi-header">{{'RELEASES.HEADERS.VIDEO-1' | translate}}</h1>
      <article class="notice">
        {{'RELEASES.CONTENT.VIDEO-1' | translate}}
        <iframe class="youtube-widget" src="https://www.youtube.com/embed/xWEn3OWUHJI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </article>
    </div>
  </div>

</div>
