import { Component, HostListener, OnInit } from '@angular/core';
import { trigger, animate, transition, style, state } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-label-page',
  templateUrl: './label-page.component.html',
  styleUrls: ['./label-page.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(800 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(800, style({opacity: 0})))
      ])
  ]
})
export class LabelPageComponent implements OnInit {

  //Play the sound.
  soundFlag: boolean = false;
  audioTrack2: any;
  audioTrack1: any;

  constructor(private router: Router) {
    // Get sound effects for this page.
    this.audioTrack2 = new Audio('../../../assets/audio/duskhorizon-sample.mp3');
    this.audioTrack2.volume = 0.5;
    this.audioTrack1 = new Audio('../../../assets/audio/aphrodite-sample.mp3');
    this.audioTrack1.volume = 0.5;
   }

  ngOnInit() {
    this.router.events.subscribe((event) => {
        if (!(event instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.audioTrack2.pause();
    this.audioTrack1.pause();
  }

  sound2() {
    let audioImage1 = document.getElementsByClassName('toggle-sound')[1];
    let audioImage2 = document.getElementsByClassName('toggle-sound')[0];
    if (this.audioTrack2.paused) {
      // Play the second track.
      this.audioTrack2.play();
      // Change the audio image of the 2nd track.
      audioImage2.setAttribute('src', '../../../assets/images/icons/pause.png');

      if (!this.audioTrack2.paused) {
        // Pause the first track.
        this.audioTrack1.pause();
        // Change the audio image of the 1st track.
        audioImage1.setAttribute('src', '../../../assets/images/icons/play.png');
      }
    } else {
      this.audioTrack2.pause();
      audioImage2.setAttribute('src', '../../../assets/images/icons/play.png');
    }
    this.audioTrack2.onended = function () {
      audioImage2.setAttribute('src', '../../../assets/images/icons/play.png');
    }

  }
  sound1() {
    let audioImage1 = document.getElementsByClassName('toggle-sound')[1];
    let audioImage2 = document.getElementsByClassName('toggle-sound')[0];
    if (this.audioTrack1.paused) {
      // Play the first track.
      this.audioTrack1.play();
      // Change the audio image.
      audioImage1.setAttribute('src', '../../../assets/images/icons/pause.png');

      if (!this.audioTrack2.paused) {
        // Pause the second track.
        this.audioTrack2.pause();
        // Change the audio image of the 1st track.
        audioImage2.setAttribute('src', '../../../assets/images/icons/play.png');
      }
    } else {
      this.audioTrack1.pause();
      audioImage1.setAttribute('src', '../../../assets/images/icons/play.png');
    }
    this.audioTrack1.onended = function () {
      audioImage1.setAttribute('src', '../../../assets/images/icons/play.png');
    }

  }

  toggleSound2() {
    this.sound2();
  }
  toggleSound1() {
    this.sound1();
  }

}
