<div id="intro-panel" [@simpleFadeAnimation]="'in'">
 <div class="panel">
    <h1 class="lofi-header">Submit your LoFi content</h1>
    <article class="notice">
      Κάνε submit τη LoFi τέχνη σου είτε αυτή είναι μουσική είτε digital art
      και δώσε μια ευκαιρία στη δουλειά σου να εμφανιστεί στα project του Greek LoFi Community.
      Οι content curators μας ελέγχουν τα submissions και αν η υποβολή σου ταιριάζει σε κάποιο από τα project μας
      όπως το Greek LoFi Radio, μελλοντικά playlists, video clips, lofi edits ή vlogs των live event μας, θα σε
      ειδοποιήσουμε για το artist feature.
      <br>
      <div id="criteria-panel">
        <span class="criteria-header">Κριτήρια Υποβολής</span>:
        <ol>
          <li>
            Πρέπει να είσαι μέλος στον <a title="Discord Server" href="https://discord.gg/3McA7S8">Discord Server</a> μας.
          </li>
          <li>
            Πρέπει να είσαι από την <strong>Ελλάδα</strong> ή την <strong>Κύπρο</strong>.
          </li>
          <li>
            Η υποβολή πρέπει να <strong>ΜΗΝ</strong> περιέχει <a href="https://en.wikipedia.org/wiki/Parental_Advisory">explicit περιεχόμενο</a>.
          </li>
        </ol>
        <span class="criteria-header">Φόρμα Υποβολής</span>:
        <br>
        Submissions comming soon...
        <br>
        <!--
        <small>Για οποιαδήποτε απορία ή διευκρίνηση, hit us up στο <a href="#">#📻greek-lofi-radio channel</a> του Discord Server</small>
        -->
      </div>
    </article>
    <img id="submit-letters" src="../../../assets/images/tape.gif" alt="Submission Mixtape"/>
  </div>
</div>
