<div class="page-container" [@simpleFadeAnimation]="'in'">

  <div class="panel">
    <div class="inner-panel">

      <h1 class="lofi-header">{{'ABOUT.HEADERS.LABEL' | translate}}</h1>
      <article class="notice">
        {{'ABOUT.CONTENT.LABEL' | translate}}
        <a href="https://discord.gg/3McA7S8">{{'ABOUT.CONTENT.DISCORD' | translate}}</a>
      </article>
    </div>
  </div>

  <div class="panel yellow-panel">
    <div class="inner-panel">

      <h1 class="lofi-header">{{'ABOUT.HEADERS.TEAM' | translate}}</h1>
      <article class="notice">
        {{'ABOUT.CONTENT.TEAM' | translate}} <a href="https://www.messenger.com/t/LoFi.gr">{{'ABOUT.CONTENT.CHAT' | translate}}</a> 😊
      </article>

      <br />
      <!-- Always add only up to 4 people in a single div with a team-members class. -->
      <div class="team-members">
        <figure>
          <a href="https://open.spotify.com/artist/1UUfEEstgd64AnExToS403">
            <img class="member-photo images" src="../../../assets/images/team/neoplasma.jpg" alt="team member neoplasma" />
          </a>
          <figcaption><h3 class="member-name">Neoplasma</h3>music<br></figcaption>
        </figure>
        <figure>
          <a href="http://nikitas.io">
            <img class="member-photo images" src="../../../assets/images/team/nikitas.jpg" alt="team member nikitas" />
          </a>
          <figcaption><h3 class="member-name">Nikitas I/O</h3>software / marketing<br></figcaption>
        </figure>
        <figure>
          <a href="https://www.instagram.com/iotakos/">
            <img class="member-photo images" src="../../../assets/images/team/iotakos.jpg" alt="team member iotakos" />
          </a>
          <figcaption><h3 class="member-name">Iotakos</h3>videography<br></figcaption>
        </figure>
        <figure>
          <a href="https://open.spotify.com/artist/4ZguwlmrdElUZaO36dRTbn">
            <img class="member-photo images" src="../../../assets/images/team/ondivil.jpg" alt="team ondi vil" />
          </a>
          <figcaption><h3 class="member-name">Ondi Vil</h3>music<br></figcaption>
        </figure>

      </div>
      <div class="team-members">
        <figure>
          <a href="https://www.instagram.com/mati0.10/">
            <img class="member-photo images" src="../../../assets/images/team/mati.jpg" alt="team member mati" />
          </a>
          <figcaption><h3 class="member-name">Mati</h3>illustration<br></figcaption>
        </figure>


        <figure>
          <a href="https://www.messenger.com/t/LoFi.gr">
            <img class="member-photo images" src="../../../assets/images/team/member.png" alt="team member photo" />
          </a>
          <figcaption><h3 class="member-name">{{'ABOUT.CONTENT.YOU' | translate}}</h3>{{'ABOUT.CONTENT.TALENT' | translate}}<br></figcaption>
        </figure>
      </div>
    </div>
  </div>

  <div class="panel">
    <div class="inner-panel">
      <h1 class="lofi-header">{{'ABOUT.HEADERS.CONTACT' | translate}}</h1>
      <article class="notice">
        {{'ABOUT.CONTENT.CONTACT' | translate}}
        <a id="email" href="mailto:lofi.greece@gmail.com">lofi.greece@gmail.com</a>
      </article>
    </div>

    <div class="inner-panel twitter-panel">

      <h1 class="lofi-header">{{'ABOUT.HEADERS.TWEET' | translate}}</h1>
      <p>{{'ABOUT.CONTENT.TWEET' | translate}}</p>
      <!-- The Twitter widget -->
      <a class="twitter-timeline" data-lang="el" data-width="833" data-height="440" data-dnt="true" data-theme="light" href="https://twitter.com/LofiGr?ref_src=twsrc%5Etfw">Tweets by LofiGr</a>
    </div>
  </div>

  <div class="panel purple-panel">
    <div class="inner-panel">
      <h1 class="lofi-header">{{'ABOUT.HEADERS.DEFINITION' | translate}}</h1>
      <article class="notice">
        {{'ABOUT.CONTENT.DEFINITION' | translate}}
      </article>
    </div>
  </div>

  <div class="panel yellow-panel">
    <div class="inner-panel">
      <h1 class="lofi-header">{{'ABOUT.HEADERS.CORPORATE' | translate}}</h1>
      <article class="notice">
        {{'ABOUT.CONTENT.CORPORATE' | translate}}
        <br>
        <br>
        {{'ABOUT.CONTENT.GEMI' | translate}}
      </article>
    </div>
  </div>
</div>
