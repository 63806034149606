import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    public translateService: TranslateService,
  ) {

    // Register translation languages.
    translateService.addLangs(['en', 'gr']);

    // Get the language from local storage.
    let storedLang = localStorage.getItem('lang');
    if ( !((storedLang == 'en') || (storedLang == 'gr')) ) {
      // Get the browser's language.
      const browserLang = translateService.getBrowserLang();
      console.log('The browser lang: ', browserLang);
      if (browserLang == 'el') {
        // Set a default language.
        translateService.setDefaultLang('gr');
        localStorage.setItem('lang', 'gr');
      } else {
        // Set a default language.
        translateService.setDefaultLang('en');
        localStorage.setItem('lang', 'en');
      }
    } else {
      // Set the stored language as the default.
      translateService.setDefaultLang(storedLang);
    }

  }

  ngOnInit() {

  }


}
