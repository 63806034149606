<ngb-carousel>
  <ng-template ngbSlide *ngFor="let image of images; let i=index;">
    <div class="wrapper">
      <a [href]="image.link" alt="image-link">
        <img class="carousel-image" [src]="image.src" alt="Random first slide">
      </a>
    </div>

    <!-- The rest of the carousel items should have external links -->
    <a [href]="image.link" alt="image-link">
      <div class="carousel-caption">
        <h3>{{ image.title }}</h3>
        <p>{{ image.short }}</p>
      </div>
    </a>

  </ng-template>
</ngb-carousel>
