import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// Import pages
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';

import { LabelPageComponent } from './pages/label-page/label-page.component';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { SubmissionPageComponent } from './pages/submission-page/submission-page.component';
import { ArtistsPageComponent } from './pages/artists-page/artists-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'releases', component: LabelPageComponent },
  { path: 'releases/aphrodites-memories', redirectTo: '/releases', pathMatch: 'full'},
  { path: 'artists', component: ArtistsPageComponent },
  { path: 'shop', component: ShopPageComponent },
  { path: 'submissions', component: SubmissionPageComponent },
  { path: 'about', component: AboutPageComponent },
];

const extraOptions: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
