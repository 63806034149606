import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Page Imports
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ShopPageComponent } from './pages/shop-page/shop-page.component';
import { SubmissionPageComponent } from './pages/submission-page/submission-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';

// Layout Imports
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';

// Third Party Imports
import { FacebookModule } from 'ngx-facebook';
import { LabelPageComponent } from './pages/label-page/label-page.component';
import { ArtistsPageComponent } from './pages/artists-page/artists-page.component';
import { CarouselComponent } from './layout/carousel/carousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ShopPageComponent,
    SubmissionPageComponent,
    AboutPageComponent,
    HeaderComponent,
    FooterComponent,
    LabelPageComponent,
    ArtistsPageComponent,
    CarouselComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,

    // Third Party Imports
    FacebookModule.forRoot(),


    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
