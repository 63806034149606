<header>
  <div class="language-selection">
    <button class="language" [ngClass]="{'selected-lang':currentLang === 'en'}" type="button" (click)="translateLanguageTo('en')">English</button>
    <button class="language" [ngClass]="{'selected-lang':currentLang === 'gr'}" type="button" (click)="translateLanguageTo('gr')">Ελληνικά</button>
  </div>

  <div id="hamburger-container">
    <input id="hamburger" type="button" value="☰" tabindex="0" aria-label="Menu button" (click)="onOpenMenu()"/>
  </div>
  <!-- The Logo image -->
  <div routerLink="/" id="logo-container">
    <div class="ring">
      <img id="logo" src="../../../assets/images/logo/circle_logo.png" alt="LoFi.gr logo"/>
    </div>
  </div>
  <div id="menubar">

      <nav>
          <ul class="ulmenu">
              <li id="close-burger" (click)="onCloseMenu()">✕</li>
              <li>
                  <a routerLink="/">{{'NAVBAR.HOME' | translate}}</a>
              </li>
              <li>
                  <a routerLink="/releases">{{'NAVBAR.RELEASES' | translate}}</a>
              </li>
              <li>
                  <a routerLink="/artists">{{'NAVBAR.ARTISTS' | translate}}</a>
              </li>
              <!--
              <li>
                  <a routerLink="/shop">Shop</a>
              </li>
              <li>
                <a routerLink="/submissions">Υποβολές</a>
              </li>
              -->
              <li>
                  <a routerLink="/about">{{'NAVBAR.ABOUT' | translate}}</a>
              </li>
          </ul>
      </nav>
  </div>



</header>

