import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
// Import package to handle meta tags on the app's <head></head>
import { Title, Meta } from '@angular/platform-browser';
// Import router.
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { TranslateService } from '@ngx-translate/core';

// Declasre the google tag function.
declare var gtag;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // The currently selected language.
  currentLang:string = 'en';

  // Flag to check if this is the user's first visit to a page.
  firstVisit: boolean = true;
  // Sets initial value to true to show loading spinner on first load.
  loading: boolean = true;

  menu: HTMLElement;
  burger: HTMLElement;
  closeBurger: HTMLElement;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private metaTagService: Meta,
    private titleService: Title,
    public translateService: TranslateService,
  ) {
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  ngOnInit(): void {

    // Get the language from local storage.
    let storedLang = localStorage.getItem('lang');
    this.translateLanguageTo(storedLang);

    this.menu = document.getElementById("menubar") as HTMLElement;
    this.burger = document.getElementById('hamburger') as HTMLElement;
    this.closeBurger = document.getElementById("close-burger") as HTMLElement;

    /*--------TOUCH EVENTS---------*/
    this.renderer.listen('document', 'touchstart', handleTouchStart);
    this.renderer.listen('document', 'touchmove', handleTouchMove);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return evt.touches // browser API
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if ( ! xDown || ! yDown ) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        let menu = document.getElementById("menubar");
        let burger = document.getElementById('hamburger');
        // Most significant
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
          /* left swipe */
          if ( xDiff > 0 ) {
            // Check if the side-menu is closed so you can open it.
            // menu.classList.add('open');
            // burger.classList.add('open');

            /* right swipe */
          } else {
            // Check if the side-menu is opened so you can close it.
            menu.classList.remove('open');
            burger.classList.remove('open');
          }
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */
            } else {
                /* down swipe */
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };
  }

  // Switch language.
  translateLanguageTo(lang: string) {
    // Check if the language has changed.
    if (this.currentLang !== lang) {

      // Calling this method loads the corresponding [lang].json file and
      // updates the language on the page without reloading the application.
      this.translateService.use(lang);
      // Set the currently selected language.
      this.currentLang = lang;
      // Set the currently selected language to the local storage.
      localStorage.setItem('lang', lang);

      // Animate the ring.
      this.animateRing();
    }
  }

  /**
   * Animate the ring around the logi.gr logo.
   */
  animateRing() {
    // Get the ring.
    let ring = document.getElementsByClassName('ring') as HTMLCollectionOf<HTMLElement>;
    // Clone the ring div element.
    var ringClone = ring[0].cloneNode(true);
    // Replace the existing ring with its clone to restart its animation.
    ring[0].parentNode.replaceChild(ringClone, ring[0]);
  }

  // Shows and hides the loading spinner during RouterEvent changes.
  navigationInterceptor(event: RouterEvent): void {
    // Navigation Start.
    if (event instanceof NavigationStart) {
      // Animate the ring.
      this.animateRing();

      // Raise the navigation flag.
      this.loading = true;

    }

    // Navigation End.
    if (event instanceof NavigationEnd) {
      // Google Tag for analytics
      // gtag('config', 'UA-151194258-1', {
      //   'page_path': event.urlAfterRedirects
      // });

      // Set the title and meta tags for each page upon navigating there.
      if (this.router.url == '' || this.router.url == '/') {
        this.titleService.setTitle('LoFi.gr - Greek LoFi Community');
        this.metaTagService.updateTag({ name: 'description', content: 'Greek LoFi Community - LoFi Greece' });
        this.metaTagService.updateTag({ name: 'keywords', content: 'lofi, lo fi, lo-fi, community, chill, beats, radio, aesthetic, aesthetics, Inhinito, inhinito, greek, greece, hellas' });
      } else if (this.router.url == '/releases') {
        this.titleService.setTitle('LoFi.gr - Record Label Releases');
        this.metaTagService.updateTag({ name: 'description', content: 'Greek LoFi Record Label Releases' });
        this.metaTagService.updateTag({ name: 'keywords', content: 'lofi, lo fi, lo-fi, releases, label, record label, record, music, team, community, chill, beats, radio, hiphop, hip hop, hip-hop, Inhinito, inhinito, greek, greece, hellas' });
      } else if (this.router.url == '/artists') {
        this.titleService.setTitle('LoFi.gr - Greek LoFi Artists');
        this.metaTagService.updateTag({ name: 'description', content: 'Greek LoFi Community Artists' });
        this.metaTagService.updateTag({ name: 'keywords', content: 'lofi, lo fi, lo-fi, artists, music, hiphop, hip hop, hip-hop, submit, community, chill, beats, radio, artists, artist, beatmaker, beatmakers, beat-maker, beat-makers, inhinito, greek, greece, hellas' });
      } else if (this.router.url == '/about') {
        this.titleService.setTitle('LoFi.gr - About the Greek LoFi Community');
        this.metaTagService.updateTag({ name: 'description', content: 'Greek LoFi Community - About LoFi Greece' });
        this.metaTagService.updateTag({ name: 'keywords', content: 'lofi, lo fi, lo-fi, information, info, team, community, chill, beats, radio, aesthetic, aesthetics, Inhinito, inhinito, greek, greece, hellas' });
      }


      // Upon navigating to the other page, get the menu items.
      let menuItems = this.menu.getElementsByTagName("UL")[0];
      // Loop through each menu item.
      for (var i = 1; i < menuItems.children.length; i++) {
        // The list item's link.
        let item = menuItems.children[i].getElementsByTagName("A")[0];

        // Set the rest of the item's back to their original background.
        item.setAttribute('style', 'background: transparent');

        // Check which page does the current URL match.
        if (item.getAttribute('routerLink') == this.router.url) {
          // Set the background of the selected item menu to a shade of purple.
          item.setAttribute('style', 'background: #ffffff85; box-shadow:none');
        }
      }


      // Drop the navigation flag.
      this.loading = false;

      if (!this.firstVisit) {
        // Close the sidebar menu.
        this.toggleMenu();
      }
      this.firstVisit = false;

    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      // Drop the navigation flag.
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      // Drop the navigation flag.
      this.loading = false;
    }
  }

  toggleMenu() {
    let width = window.innerWidth;

    if (width <= 750) {
      //If open close it, if closed open it.
      if (!this.menu.classList.contains('open')) {
        // Open up the sidebar menu.
        this.menu.classList.add('open');
        // Fade out the hamburger button.
        this.burger.classList.add('open');
      }else{
        // Close out the sidebar menu.
        this.menu.classList.remove('open');
        // Fade in the hamburger button.
        this.burger.classList.remove('open');
      }
    }
  }

  // Hamburger menu open.
  onOpenMenu() {
    this.toggleMenu();
  }

  // Hamburger menu close.
  onCloseMenu() {
    this.toggleMenu();
  }

  // CSS selector for everything inside the sidebar menu.
  outsideBurger = '#menubar *';
  // Click event-listener for the document's body.
  @HostListener('document:click', ['$event'])
  onKeyUp(event) {
    // Check if the side-menu is opened.
    if (this.menu.classList.contains('open')){
      // Only close the menu if the user has clicked outside the menu.
      if (!event.target.matches(this.outsideBurger) && event.target != this.burger){
        this.toggleMenu();
      }
    }
  }

}
