
<div id="intro-panel" [@simpleFadeAnimation]="'in'">

  <!--

    <img id="album-cover" src="../../../assets/images/banners/aphrodite.jpg" alt="Aphrodite's Memories Album Cover">
  -->
  <ngbd-carousel-navigation></ngbd-carousel-navigation>

  <div id="header" class="after-carousel">
    <div class="panel">
      <div class="inner-panel">

        <h1 class="lofi-header">{{'HOME.HEADERS.RELEASE-2' | translate}}</h1>
        <article class="notice">
          {{'HOME.CONTENT.RELEASE-2' | translate}}
          <br />
          <a routerLink="/releases"><button class="special-button bn24">{{'HOME.BUTTONS.LISTEN' | translate}}</button></a>
          <br />
          <img id="album-cover" src="../../../assets/images/duskhorizon.jpg" alt="Dusk Horizon Cover Art" />
        </article>
      </div>
    </div>
  </div>

  <div class="panel yellow-panel">
    <div class="inner-panel">

      <h1 class="lofi-header">{{'HOME.HEADERS.COMMUNITY' | translate}}</h1>
      <article class="notice">
        {{'HOME.CONTENT.COMMUNITY' | translate}}
      </article>

      <br>
      <main>
        <div>
          <a title="Discord Server" href="https://discord.gg/3McA7S8">
            <button class="special-button bn25">{{'HOME.BUTTONS.JOIN' | translate}}</button>
          </a>
          <!-- Discord Widget -->
          <iframe src="https://discordapp.com/widget?id=616589672050589698&theme=light" width="90%" height="350" allowtransparency="true" frameborder="0"></iframe>
        </div>
      </main>
    </div>
  </div>

  <div id="header">
    <div class="panel album-panel">
      <div class="inner-panel">

        <h1 class="lofi-header">{{'HOME.HEADERS.RELEASE-1' | translate}}</h1>
        <article class="notice">
          {{'HOME.CONTENT.RELEASE-1' | translate}}
          <br />
          <a routerLink="/releases"><button class="special-button bn24">{{'HOME.BUTTONS.LISTEN' | translate}}</button></a>
          <br />
          <img id="album-cover" src="../../../assets/images/aphrodite.jpg" alt="Aphrodite's Memories Cover Art" />
        </article>
      </div>
    </div>
  </div>


  <div id="header">
    <div class="panel">
      <div class="inner-panel">
        <h1 class="lofi-header">{{'HOME.HEADERS.RADIO' | translate}}</h1>
        <article class="notice">
          {{'HOME.CONTENT.RADIO' | translate}}
        </article>
        <iframe class="youtube-widget" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLUelkIwR8-aEKzTFEG5wY0UWXtfHyB5st" frameborder="0" allow="accelerometer; autoplay; encrypted-../../../assets; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <!--
    <div class="panel">
      <h1 class="lofi-header">Support the Greek LoFi Community</h1>
      <article class="notice">
        Η Ελληνική LoFi κοινότητα βασίζεται πάνω σου για τη προώθηση των Ελλήνων LoFi καλλιτεχνών! Βοήθησε μας κάνοντας
        <div id="fb-share" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Flofi.gr&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">μια κοινοποίηση</a></div>
        ή <strong><a routerLink="/donate">μέσω μιας δωρεάς</a></strong>.
        <br>
        <span routerLink="/donate" class="emoji-link">💌</span>
      </article>
    </div>


    <div class="panel">
      <h1 class="lofi-header">Submit your LoFi Content</h1>
      <article class="notice">
        Είσαι καλλιτέχνης και θέλεις να υποβάλεις τα lofi beats ή το digital art σου για να τα κάνουμε feature;
        Τσέκαρε τη <strong><a routerLink="/submissions">σελίδα υποβολών</a></strong> μας για οδηγίες! Δεχόμαστε υποβολές μόνο από καλλιτέχνες που πληρούν συγκεκριμένα
        κριτήρια.
        <br>
        <span routerLink="/submissions"><img id="submit-mixtape" src="../../../assets/images/icons/cassete.svg" alt="submit mixtape"></span>
      </article>
    </div>
  -->



  <!--
  Lofi Shop Section
  <div class="panel yellow-panel">
    <h1 class="lofi-header">Greek LoFi Shop</h1>
    <article class="notice">
      Θέλεις να υποστηρίξεις τη LoFi σκηνή στην ελλάδα, ρίξε μια ματιά στο e-shop μας. Κάθε αγορά στιρίζει άμεσα τη κοινότητα μας.
    </article>
  </div>
  -->
</div>
