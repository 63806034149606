import {Component, DoCheck, HostListener} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngbd-carousel-navigation',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class CarouselComponent {
  showNavigationArrows = false;
  showNavigationIndicators = false;
  images = [
    {
      title: '',
      short: '',
      link: "https://lofi.gr/#/releases",
      src: "../../../assets/images/banners/duskhorizon.jpg"
    },
    {
      title: '',
      short: '',
      link: "https://lofi.gr/#/releases",
      src: "../../../assets/images/banners/aphrodite.jpg"
    },
    {
      title: '',
      short: '',
      link: "https://discord.gg/3McA7S8",
      src: "../../../assets/images/banners/discord.jpg"
    },
    {
      title: '',
      short: '',
      link: "https://youtu.be/SUyQ2rddBiw?list=PLUelkIwR8-aEKzTFEG5wY0UWXtfHyB5st",
      src: "../../../assets/images/banners/radio.jpg"}
  ];

  // The current browser language.
  currentLang: string;

  constructor(
    config: NgbCarouselConfig,
    public translate: TranslateService
  ) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = false;

    config.interval = 7000;
    config.keyboard = true;
    config.pauseOnHover = false;

    this.translateCarousel()
  }


  translateCarousel() {
    // asynchronous - gets translations then completes.
    this.translate.get(['HOME.CAROUSEL'])
      .subscribe(translations => {

        for (let i = 0; i < this.images.length; i++) {

          let slideTranslation = translations['HOME.CAROUSEL'][(i + 1).toString()];
          console.log('The translation: ', slideTranslation)
          this.images[i].title = slideTranslation.TITLE;
          this.images[i].short = slideTranslation.SHORT;

        }
        // console.log('The translations: ', translations)

      });
  }

  @HostListener("document:click", ['$event.target'])
  onClick(targetElement) {

    if (targetElement.classList.contains('language')) {

      this.translateCarousel();

    }
  }

}
