<div class="panel">

  <footer>
    <article class="notice">

      <br>
      {{'FOOTER.HEADLINE' | translate}}
      <br>
      <a href="https://inhinito.com"><img id="inhinito-logo" src="../../../assets/images/logo/inhinito_logo.png" alt="Inhinito Logo" /></a>
      <br>

      {{'FOOTER.COPYRIGHT-1' | translate}}
      <!-- The variable that holds the current year -->
      <span id="current-year">
        {{currentYear}}
      </span>
      {{'FOOTER.COPYRIGHT-2' | translate}}
      <div id="social-links">
        <a title="Youtube" href="https://www.youtube.com/channel/UCTwkZdqG9nlyZJGwXkgqvCg/"><img class="images" src="../../../assets/images/social/youtube.svg" alt="Youtube"></a>
        <a title="Instagram" href="https://www.instagram.com/lofi.gr/"><img class="images" src="../../../assets/images/social/instagram.svg" alt="Instagram"></a>
        <a title="Facebook" href="https://www.facebook.com/lofi.gr/"><img class="images" src="../../../assets/images/social/facebook.svg" alt="Instagram"></a>
        <a title="Twitter" href="https://twitter.com/LofiGr"><img class="images" src="../../../assets/images/social/twitter.svg" alt="Twitter"></a>
      </div>
    </article>
  </footer>

</div>
