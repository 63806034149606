import { Component, OnInit } from '@angular/core';
import { trigger, animate, transition, style, state } from '@angular/animations';

@Component({
  selector: 'app-artists-page',
  templateUrl: './artists-page.component.html',
  styleUrls: ['./artists-page.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(800 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(800, style({opacity: 0})))
      ])
  ]
})
export class ArtistsPageComponent implements OnInit {

  artistIDs = [
    '4ZguwlmrdElUZaO36dRTbn', // 1. Ondi Vil
    '1UUfEEstgd64AnExToS403', // 2. Neoplasma
    '2vBwAFEqxBRaAvR6F69jXy', // 3. Abstract
    '03BWUMpkLVCRUZY8g7C4kF', // 4. Bronco
    '2yTDt06lsG7dGqJuCbR88I', // 5. Lox Beats
    '6evg3DBRRbN4YNlzbeS6VN', // 6. Drips Zacheer
    '0Ql2lWx1D8fNVnaW9AM8td', // 7. defMute
    '47URGV6M9iPbmVXkucAQWk', // 8. Injijo
    '3PLqmSa29zyLdTGINzLqCZ', // 9. Jakspin
    '1aIxUlGmHt0gWTsHWz9wNC', // 10. Jan-jiki
    '33FKoUeAEFuzVHGBd9IhcL', // 11. Mindeliq
    '4CSOdm2yCuB71TxCfV105e', // 12. Kinissue
    '4agrt7irb8WmdQHjACTT6t', // 13. Kxeil
    '3tbv8PcwIqfWdsgdGcWgdY', // 14. Oui Lele
    '0MrN8mIBO80rDPdOiNYPIR', // 15. Lys
    '6MdmDH4ws2thbEQsYGUL7g', // 16. Saaaz
    '6oiMfPHT1vKJjbWvqMNAvU', // 17. Aprovoli
    '1N2neBJzN922SxIO8RG7NA', // 18. Garbagio
    '1wQ0eF0v7bU4P3m3bFRRd5', // 19. Malenus
  ] // Λείπουν μόνο fukuro και carriers.

  // The artist container in the DOM.
  artistContainer;
  // Current artist.
  currentIndex = 0;
  // Increment the artists by this amount.
  increment = 4;

  // Flag to check if all artists have been appended.
  noMore = false;

  constructor() { }

  ngOnInit(): void {
    // Shuffle the artist IDs.
    this.artistIDs = this.shuffle(this.artistIDs);
    // Get the artist container where all new artists will be appended.
    this.artistContainer = document.getElementById('artist-container');
    // Load the first arists.
    this.loadMore();
  }

  /**
   * Shuffle the artist IDs.
   * @param array The array to shuffle.
   * @returns A shuffled array.
   */
  shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  /**
   * Load more artists.
   */
  loadMore() {
    // Add more artists to the DOM.
    for (let index = 0; index < this.increment; index++) {
      // Check if all artist have been appended.
      if (this.noMore == false) {

        // Check if the index has surpased the artist IDs array length.
        if ((this.currentIndex + index) < this.artistIDs.length) {
          // Get the ID of the next artist.
          const artistID = this.artistIDs[this.currentIndex + index];

          // Append the new artist.
          this.appendArtist(artistID);
        } else {
          // All artist have been appended.
          this.noMore = true;
        }
      }
    }

    // Increment the current index by the number of artists that were added.
    this.currentIndex += this.increment;
    // Check if we've reached the end of the artistIDs array.
    if (this.currentIndex == this.artistIDs.length) {
      this.noMore = true;
    }
  }


  /**
   * Append a single artist to the DOM.
   * @param artistID
   */
  appendArtist(artistID) {
    // Create an artist element.
    let artist = `
      <iframe src="https://open.spotify.com/embed/artist/${artistID}?utm_source=generator" style="border-radius: 12px; margin-bottom: 30px;" width="100%" height="200" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    `;
    // Add the new artist to the arist container.
    this.artistContainer.insertAdjacentHTML('beforeend', artist);
  }

}
