<div class="page-container" [@simpleFadeAnimation]="'in'">
  <div class="panel">
    <div class="inner-panel">

      <h1 class="lofi-header">{{'ARTISTS.HEADERS.ARTISTS' | translate}}</h1>
      <article class="notice">
          {{'ARTISTS.CONTENT.ARTISTS' | translate}}
          <h4><i>{{'ARTISTS.CONTENT.RANDOM' | translate}}</i> 👇</h4>

          <div id="artist-container">
            <!-- Append the artists here. -->
          </div>
      </article>

      <button class="special-button bn24" (click)="loadMore()" *ngIf="!noMore">{{'ARTISTS.BUTTONS.LOAD-MORE' | translate}}</button>
    </div>
  </div>
</div>
